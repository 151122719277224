<template>
  <div class="mt-base">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">No. Pengajuan</label>
        <vs-input class="w-full" :value="initData.no_pengajuan" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Tgl. Pengajuan</label>
        <vs-input class="w-full" :value="initData.tgl_pengajuan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tgl. Mulai Publish</label>
        <vs-input class="w-full" :value="initData.tgl_mulai_publish" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tipe</label>
        <vs-input class="w-full" :value="initData.tipe_platform" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.nama_proyek" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Keterangan Pengajuan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="initData.keterangan" readonly/>
      </div>
    </div>

    <vs-divider class="mt-base">Detail Media</vs-divider>
    <div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">No Media</label>
          <vs-input class="w-full" :value="initData.media.no_media" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Platform</label>
          <vs-input class="w-full" :value="initData.media.nama_platform" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">File</label>
          <div>
            <vx-input-group>
              <vs-input class="w-full" :value="initData.media.files_url ? (initData.media.files_url.length + ' File') : null" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-image" :disabled="!initData.media.files_url || initData.media.files_url.length < 1" @click="showGallery(initData.media.files_url)">Preview</vs-button>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Judul</label>
          <vs-input class="w-full" :value="initData.media.judul" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Deskripsi</label>
          <div class="p-3 overflow-auto border border-solid d-theme-border-grey-light">
            <div v-html="initData.media.deskripsi"></div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="initData.tipe_platform === 'ADVERTISING'">
      <vs-divider class="mt-base">Detail Advertising</vs-divider>
      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. Selesai Publish</label>
            <vs-input class="w-full" :value="initData.tgl_selesai_publish" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Biaya</label>
            <v-money class="w-full" :value="initData.biaya" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Sisa Saldo</label>
            <v-money class="w-full" :value="initData.sisa_saldo" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Lokasi</label>
            <div class="w-full flex flex-wrap items-center overflow-x-auto">
              <vs-chip v-for="(item, index) in initData.lokasi" :key="index">{{ item }}</vs-chip>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="initData.tipe_platform === 'BROADCAST'">
      <vs-divider class="mt-10">Broadcast Recipients</vs-divider>
      <div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="w-full border border-solid d-theme-border-grey-light h-screen-50 overflow-y-auto">
              <table class="w-full table-fixed border-collapse">
                <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                  <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                    <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-10">#</th>
                    <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Nama</th>
                    <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Email</th>
                    <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Telp</th>
                  </tr>
                </thead>
                <tbody class="font-medium">
                  <tr v-for="(item, index) in initData.recipients" :key="index">
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ index + 1 }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.nama }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.email }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.telp }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'

export default {
  name: 'TabDetail',
  props: ['isActive', 'initData'],
  components: {
    VMoney
  },
  data () {
    return {}
  },
  methods: {
    showGallery (listMedia) {
      const lightbox = GLightbox()
      const elements = _.map(listMedia, item => ({ href: item }))
      lightbox.setElements(elements)
      lightbox.open()
    }
  }
}
</script>
